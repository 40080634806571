import * as React from 'react';

function FavoriteThing({ category, thing }) {
  return thing?.length > 0 ? (
    <div
      className="
                w-[270px]
                font-roboto
                text-18
                text-neutral-400
                text-center
                leading-[30px]
            "
    >
      <h4
        className="
                    font-bold
                "
      >
        {category}
        &#58;
      </h4>
      <p
        className="
                    min-h-[83px]
                    mobile:min-h-[82px]
                "
      >
        {thing}
      </p>
    </div>
  ) : (
    ''
  );
}

export default FavoriteThing;
