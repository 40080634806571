import { graphql } from 'gatsby';
import * as React from 'react';

import missionImage from '../images/missionheader.svg';
import preMobileMissionImage from '../images/missionHeaderSmall.svg';
import Layout from '../components/layout/layout';
import Section0 from '../page-components/peo/section-0/section-0';
import Section1 from '../page-components/peo/section-1/section-1';
import Section2 from '../page-components/peo/section-2/section-2';
import Section3 from '../page-components/peo/section-3/section-3';

function PeoPage({ data, location }) {
  const headerContent = {
    heading: 'PEO DHMS',
    subheading:
      'PEO DHMS is an acquisition organization with a direct reporting relationship to the Office of the Under Secretary of Defense for Acquisition and Sustainment (OUSD(A&S)), and administratively attached to the Defense Health Agency.',
    image: missionImage,
    preMobileImage: preMobileMissionImage,
    alt: 'People with a mission',
  };

  return (
    <Layout
      title="PEO DHMS | HIVE"
      headerContent={headerContent}
      location={location}
    >
      <Section0 pageData={data.contentfulPeodhmsPage} />
      <Section1 pageData={data.contentfulPeodhmsPage} />
      <Section2 pageData={data.contentfulPeodhmsPage} />
      <Section3 pageData={data.contentfulPeodhmsPage} />
    </Layout>
  );
}

export default PeoPage;

export const pageQuery = graphql`
  {
    contentfulPeodhmsPage {
      sponsorCornerHeading
      sponsorTitle
      sponsorText {
        sponsorText
      }
      videoLink
      sponsorBadges {
        description
        file {
          url
        }
      }
      sponsorBadgesText
      programOfficesTitle
      programOffices {
        name
        description {
          description
        }
        capabilities
        products
        image {
          description
          file {
            url
          }
        }
      }
      partnershipMapping {
        description
        file {
          url
        }
      }
      idealsTitle
      idealBadges {
        description
        file {
          url
        }
      }
      idealTitle
      idealText
      membersTitle
      members {
        id
        name
        position
        photo {
          file {
            url
          }
        }
        about {
          about
        }
        book
        sportsTeam
        flowerplant
        food
        movie
        walkUpSong
        yearsInGovernment
        statesVisited
        cupsOfCoffeePerDay
      }
    }
  }
`;
