import * as React from 'react';

import { badge } from './badge.module.css';

function getImgWidth(imgIdx) {
  switch (imgIdx) {
    case 0:
      return '43px';
    case 1:
      return '38px';
    case 2:
      return '37px';
    case 3:
      return '47px';
    default:
      return '0px';
  }
}

function Badge({ alt, img, text, imgIdx }) {
  return (
    <div className={`xl:basis-[302px] lg:basis-[245px] ${badge}`}>
      <div
        className="
        relative
        mx-auto
        w-[55px]
        h-[55px]
      "
      >
        <img
          className={`basis-[${getImgWidth(
            imgIdx
          )}] h-auto mx-auto absolute bottom-0 left-[50%] translate-x-[-50%]`}
          src={img}
          alt={alt}
        />
      </div>
      <p
        className="
                font-roboto
                text-neutral-400
                text-18
                mobile:text-17
                leading-[30px]
                text-center
                mt-[10px]
            "
      >
        {text}
      </p>
    </div>
  );
}

export default Badge;
