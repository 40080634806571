import * as React from 'react';

function Badge({ alt, img, text, title }) {
  return (
    <div
      className="
            xl:w-[226px]
            xl:mr-[65px]
            lg:w-[206px]
            lg:mr-[23px]
            mobile:w-[259px]
        "
    >
      <div
        className="
        relative
        mx-auto
        w-[57px]
        h-[57px]
      "
      >
        <img
          className="
          h-auto
          mx-auto
          absolute
          bottom-0
          left-[50%]
          translate-x-[-50%]
        "
          src={img}
          alt={alt}
        />
      </div>
      <h3
        className="
                text-primary
                font-roboto
                font-bold
                text-18
                mobile:text-17
                leading-[30px]
                text-center
                mt-[15px]
            "
      >
        {title}
      </h3>
      <p
        className="
                    font-roboto
                    text-neutral-400
                    text-18
                    mobile:text-17
                    leading-[30px]
                    text-center
                    mt-[7px]
                    pb-[75px]
                "
      >
        {text}
      </p>
    </div>
  );
}

export default Badge;
