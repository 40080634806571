import * as React from 'react';

import { container, middleStat } from './member-stats.module.css';

function MemberStats({ coffee, states, years }) {
  return (
    <div
      className={`
            ${container}
            grid
            grid-cols-3
            h-[148px]
            content-center
            text-center
            text-white
            mobile:grid-cols-1
            mobile:h-auto
            mobile:w-full
            mobile:justify-center
            mobile:items-center
            mobile:pt-[13px]
            mobile:pb-[15px]
        `}
    >
      <div
        className="
                h-[100px]
                lg:pt-[15px]
                mobile:h-[223px]
            "
      >
        <h1
          className="
                    font-rubik
                    font-bold
                    text-40
                    mobile:text-48
                    leading-[40px]
                    mobile:leading-[133px]
                "
        >
          {years}
        </h1>
        <h2
          className="
                    font-roboto
                    text-18
                    mobile:text-24
                    leading-[30px]
                "
        >
          Years in Government
        </h2>
      </div>
      <div
        className="
                lg:hidden
                mobile:block
                w-[118px]
                h-[2px]
                bg-white
                mx-auto
                mobile:mb-[21px]
                mobile:mt-[7px]
            "
      />
      <div
        className={`
                    ${middleStat}
                    h-[100px]
                    lg:pt-[15px]
                    mobile:h-[223px]
                `}
      >
        <h1
          className="
                    font-rubik
                    font-bold
                    text-40
                    mobile:text-48
                    leading-[40px]
                    mobile:leading-[140px]
                "
        >
          {states}
        </h1>
        <h2
          className="
                    font-roboto
                    text-18
                    mobile:text-24
                    leading-[30px]
                "
        >
          States Visited
        </h2>
      </div>
      <div
        className="
                lg:hidden
                mobile:block
                w-[118px]
                h-[2px]
                bg-white
                mx-auto
                mobile:mb-[21px]
                mobile:mt-[7px]
            "
      />
      <div
        className="
                h-[100px]
                lg:pt-[15px]
                mobile:h-[223px]
            "
      >
        <h1
          className="
                    font-rubik
                    font-bold
                    text-40
                    mobile:text-48
                    leading-[40px]
                    mobile:leading-[140px]
                "
        >
          {coffee}
        </h1>
        <h2
          className="
                    font-roboto
                    text-18
                    mobile:text-24
                    leading-[30px]
                "
        >
          Cups of Coffee/Day
        </h2>
      </div>
    </div>
  );
}

export default MemberStats;
