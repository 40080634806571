import * as React from 'react';
import Badge from './components/badge/badge';

function Section2({ pageData }) {
  return (
    <section className="w-full mobile:px-[15px]">
      <h2
        className="
                font-roboto
                font-bold
                text-32
                mobile:text-24
                text-primary
                text-center
                leading-[50px]
                mobile:leading-[30px]
                w-[744px]
                mobile:w-[322px]
                smMobile:w-full
                smMobile:px-[12px]
                mx-auto
                mb-[102px]
            "
      >
        {pageData.idealsTitle}
      </h2>
      <div
        className="
                flex
                justify-center
                mobile:flex-col
                mobile:items-center
                smMobile:w-full
                smMobile:px-[12px]
            "
      >
        {pageData.idealBadges.map((badge, idx) => (
          <Badge
            alt={badge.description}
            img={badge.file.url}
            text={pageData.idealText[idx]}
            title={pageData.idealTitle[idx]}
            key={badge.description}
          />
        ))}
      </div>
    </section>
  );
}

export default Section2;
