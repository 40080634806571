import * as React from 'react';

function ProgramOffice({
  capabilities,
  description,
  img,
  imgDescription,
  name,
  products,
  which,
}) {
  return (
    <div className="mobile:mb-[75px]">
      <div className="flex mobile:flex-col">
        <div
          className="
                    xl:basis-[265px]
                    lg:basis-[188px]
                    mobile:w-[188px]
                    shrink-0
                    xl:mr-[62px]
                    lg:mr-[22px]
                    mobile:mx-auto
                    mobile:mb-[23px]
                "
        >
          <img
            className={`
                        block
                        mx-auto
                        ${which === 0 ? 'w-full h-auto' : ''}
                    `}
            alt={imgDescription}
            src={img}
          />
        </div>
        <div>
          <p
            className="
                        font-roboto
                        text-18
                        text-gray
                        mobile:text-17
                        leading-[30px]
                        mb-[38px]
                    "
          >
            <span
              className="
                            text-blue
                            font-bold
                        "
            >
              {name}
            </span>
            &nbsp;
            {description}
          </p>
          <div
            className="
                        flex
                        mobile:flex-col
                        text-gray
                        text-18
                        mobile:text-17
                        leading-[30px]
                        font-roboto
                        h-[363px]
                        mobile:h-auto
                    "
          >
            <div
              className="
                            xl:w-[317px]
                            lg:w-[290px]
                            mobile:w-full
                            xl:mr-[72px]
                            lg:mr-[66px]
                            mobile:shrink-0
                            mobile:mb-[25px]
                        "
            >
              <h3 className="font-bold">Capabilities:</h3>
              <ul className="list-disc pl-[30px]">
                {capabilities.map((capability) => (
                  <li key={capability}>{capability}</li>
                ))}
              </ul>
            </div>
            <div
              className="
                            xl:w-[321px]
                            lg:w-[293px]
                            mobile:w-full
                            mobile:shrink-0
                        "
            >
              <h3 className="font-bold">Major Products: </h3>
              <ul className="list-disc pl-[30px]">
                {products.map((product) => (
                  <li key={product}>{product}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramOffice;
