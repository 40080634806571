import * as React from 'react';
import { useState } from 'react';
import FavoriteThing from './components/favorite-thing/favorite-thing';
import MemberStats from './components/member-stats/member-stats';

import { wrapper } from './active-member.module.css';

function ActiveMember({ member }) {
  const [isBioExpanded, setIsBioExpanded] = useState(false);
  const {
    about,
    book,
    cupsOfCoffeePerDay,
    flowerplant,
    food,
    movie,
    name,
    photo,
    position,
    sportsTeam,
    statesVisited,
    walkUpSong,
    yearsInGovernment,
  } = member;

  const categoriesAndThings = [
    ['Book', book],
    ['Sports Team', sportsTeam],
    ['Flower/Plant', flowerplant],
    ['Food', food],
    ['Movie', movie],
    ['Walk Up Song', walkUpSong],
  ];

  return (
    <div
      className={`
      ${isBioExpanded ? 'overflow-y-auto' : ''}
      max-h-full
      bg-white
      ${wrapper}
      flex
      flex-col
    `}
    >
      <div
        className="
                xl:flex
                mb-[27px]
                xl:w-[886px]
                lg:w-[616px]
                lg:line-clamp-[14]
                mobile:w-full
                mx-auto
            "
      >
        <div
          className="
                    font-roboto
                    text-18
                    leading-[50px]
                    font-bold
                    text-neutral-400
                    lg:hidden
                    mobile:block
                "
        >
          <h2
            className="
                        font-rubik
                        text-primary
                        text-40
                        mobile:text-32
                        mobile:leading-[40px]
                    "
          >
            {name}
          </h2>
          <h3 className="mobile:leading-[45px]">{position}</h3>
        </div>
        <img
          className="
                        xl:basis-[248px]
                        lg:w-[248px]
                        mobile:w-[100%]
                        lg:float-left
                        mobile:float-none
                        mobile:block
                        mobile:mx-auto
                        lg:h-[248px]
                        mobile:h-auto
                        mr-[41px]
                        shrink-0
                        mobile:mt-[28px]
                        mobile:mb-[69px]
                    "
          src={photo.file.url}
          alt={name}
        />
        <p
          className="
                    leading-[30px]
                    text-17
                    font-roboto
                    text-neutral-400
                    font-normal
                    lg:hidden
                    mobile:block
                "
        >
          {about.about}
        </p>
        <div
          className="
                    font-roboto
                    text-18
                    leading-[50px]
                    font-bold
                    text-neutral-400
                    lg:block
                    mobile:hidden
                "
        >
          <h2
            className="
                        font-rubik
                        text-primary
                        text-40
                        mobile:text-32
                        mobile:leading-[40px]
                        lg:leading-[30px]
                    "
          >
            {name}
          </h2>
          <h3 className="leading-[30px] mt-[5px]">{position}</h3>
          <p
            className={`
                        leading-[30px]
                        font-normal
                    `}
          >
            <span className="lg:hidden mobile:inline-block">{about.about}</span>
            <span
              className="
              mobile:invisible
              lg:visible
            "
            >
              {about.about.substring(0, 447)}
              <span
                className={
                  isBioExpanded || about.about.length < 447
                    ? 'hidden'
                    : 'inline-block'
                }
              >
                ...
              </span>
              <span
                className={`
                mobile:hidden
                leading-[30px]
                font-normal
                ${isBioExpanded ? '' : 'lg:hidden'}
              `}
              >
                {about.about.substring(447)}
              </span>
            </span>
            <button
              type="button"
              onClick={() => setIsBioExpanded(!isBioExpanded)}
              className={`
                ${about.about.length < 447 ? 'hidden' : ''}
                mobile:hidden
                text-center
                font-roboto
                font-bold
                text-17
                text-orange
                leading-[35px]
                ml-[8px]
              `}
            >
              <span
                className={`
                ${isBioExpanded ? 'hidden' : 'block'}
              `}
              >
                Read More &gt;&gt;
              </span>
              <span
                className={`
                ${isBioExpanded ? 'block' : 'hidden'}
              `}
              >
                Read Less &lt;&lt;
              </span>
            </button>
          </p>
        </div>
      </div>
      <div
        className="
                mobile:block
                lg:hidden
                mt-[63px]
                mb-[70px]
                mx-[-15px]
            "
      >
        <MemberStats
          years={yearsInGovernment}
          coffee={cupsOfCoffeePerDay}
          states={statesVisited}
        />
      </div>
      <div
        className="
                grid
                xl:grid-cols-3
                lg:grid-cols-2
                mobile:grid-cols-1
                mobile:justify-items-center
                xl:w-[886px]
                lg:w-[578px]
                mobile:w-[270px]
                mx-auto
            "
      >
        {categoriesAndThings.map(([category, thing]) => (
          <FavoriteThing
            key={`${name}${category}${thing}`}
            category={category}
            thing={thing}
          />
        ))}
      </div>
      <div
        className="
                xl:pt-[28px]
                mobile:hidden
            "
      >
        <MemberStats
          years={yearsInGovernment}
          coffee={cupsOfCoffeePerDay}
          states={statesVisited}
        />
      </div>
    </div>
  );
}

export default ActiveMember;
