import * as React from 'react';
import { useState } from 'react';
import Modal from '../../../components/modal/modal';
import ActiveMember from './components/active-member/active-member';
import Member from './components/member/member';

function getMemberLastName(memberName) {
  const memberNames = memberName.split(' ');

  return memberNames[memberNames.length - 1];
}

function Section3({ pageData }) {
  const [activeMember, setActiveMember] = useState(null);
  const [isMemberListExpanded, setIsMemberListExpanded] = useState(false);

  return (
    <section className="w-full mobile:px-[15px]">
      <div
        className="
                    xl:w-[1112px]
                    lg:w-[836px]
                    mobile:w-[387px]
                    smMobile:w-full
                    smMobile:px-[12px]
                    mx-auto
                "
      >
        <h2
          className="
                    font-rubik
                    font-bold
                    text-40
                    mobile:text-32
                    text-primary
                    leading-[50px]
                    lg:mb-[100px]
                    mobile:mb-[100px]
                "
        >
          {pageData.membersTitle}
        </h2>
        <div
          className={`
                    grid
                    xl:grid-cols-4
                    lg:grid-cols-3
                    mobile:grid-cols-2
                    smMobile:grid-cols-1
                    gap-x-[64px]
                    mobile:gap-x-[21px]
                    gap-y-[45px]
                    mobile:gap-y-[50px]
                    xl:w-[1040px]
                    lg:w-[764px]
                    mobile:w-[387px]
                    smMobile:w-full
                    mx-auto
                    mb-[64px]
                    ${
                      isMemberListExpanded
                        ? 'mobile:h-auto smMobile:h-auto'
                        : 'overflow-y-hidden mobile:h-[625px] smMobile:h-[1225px]'
                    }
                `}
        >
          {pageData.members
            .sort((memberA, memberB) => {
              const lastNameA = getMemberLastName(memberA.name).toLowerCase();
              const lastNameB = getMemberLastName(memberB.name).toLowerCase();

              return lastNameB - lastNameA;
            })
            .map((member) => (
              <Member
                member={member}
                key={member.id}
                onMemberSelected={() =>
                  window.innerWidth < 980
                    ? (() => {
                        window.location.href = `${window.location.origin}/peo/member/${member.id}`;
                      })()
                    : setActiveMember(member)
                }
              />
            ))}
        </div>
        <button
          type="button"
          onClick={() => setIsMemberListExpanded(!isMemberListExpanded)}
          className="
            mobile:flex
            lg:hidden
            justify-center
            w-full
            font-roboto
            font-bold
            text-17
            text-orange
            leading-[35px]
            mt-[26px]
            mb-[129px]
          "
        >
          <span
            className={`
            ${isMemberListExpanded ? 'hidden' : 'block'}
          `}
          >
            View More &gt;&gt;
          </span>
          <span
            className={`
            ${isMemberListExpanded ? 'block' : 'hidden'}
          `}
          >
            View Less &lt;&lt;
          </span>
        </button>
      </div>
      {activeMember !== null ? (
        <Modal onClose={() => setActiveMember(null)}>
          <ActiveMember member={activeMember} />
        </Modal>
      ) : (
        ''
      )}
    </section>
  );
}

export default Section3;
