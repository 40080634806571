import * as React from 'react';

function Member({ onMemberSelected, member }) {
  const { name, photo, position } = member;

  return (
    <div
      onClick={() => onMemberSelected()}
      onKeyUp={() => onMemberSelected()}
      role="button"
      tabIndex="0"
      className="
            font-roboto
            text-18
            mobile:text-17
            lg:w-[212px]
            mobile:w-[183px]
            smMobile:mx-auto
            cursor-pointer
        "
    >
      <img
        className="
                w-full
                h-auto
            "
        src={photo.file.url}
        alt={name}
      />
      <h3
        className="
                font-bold
                text-primary
                leading-[30px]
                mt-[7px]
            "
      >
        {name}
      </h3>
      <h4
        className="
                text-neutral-400
                leading-[30px]
            "
      >
        {position}
      </h4>
    </div>
  );
}

export default Member;
