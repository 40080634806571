import * as React from 'react';
import ProgramOffice from './components/program-office/program-office';

function Section1({ pageData }) {
  const { programOfficesTitle, programOffices, partnershipMapping } = pageData;
  return (
    <section className="w-full mobile:px-[15px]">
      <div
        className="
                xl:w-[1197px]
                lg:w-[890px]
                smMobile:w-full
                smMobile:px-[12px]
                mx-auto
            "
      >
        <h2
          className="
                    text-32
                    leading-[30px]
                    font-bold
                    font-roboto
                    text-primary
                    mt-[75px]
                    xl:mb-[75px]
                    lg:mb-[83px]
                    mobile:mb-[55px]
                "
        >
          {programOfficesTitle}
        </h2>
        {programOffices.map((programOffice, idx) => (
          <ProgramOffice
            key={programOffice.name}
            which={idx}
            capabilities={programOffice.capabilities}
            description={programOffice.description.description}
            img={programOffice.image.file.url}
            imgDescription={programOffice.image.description}
            name={programOffice.name}
            products={programOffice.products}
          />
        ))}
      </div>
      <div
        className="
                xl:w-[1199px]
                lg:w-[890px]
                mobile:w-full
                mobile:px-[12px]
                mx-auto
                lg:mt-[145px]
                mb-[207px]
            "
      >
        <img
          className="w-full h-auto"
          src={partnershipMapping.file.url}
          alt={partnershipMapping.description}
        />
      </div>
    </section>
  );
}

export default Section1;
