import * as React from 'react';

import { modalBody, modalChildren, modalClose } from './modal.module.css';

function Modal({ children, onClose }) {
  return (
    <section
      className="
            fixed
            inset-0
            z-[51]
            h-[100vh]
        "
      role="button"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      }}
      onClick={() => onClose()}
      onKeyUp={() => onClose()}
      tabIndex="0"
    >
      <div
        className={`
                ${modalBody}
                fixed
                top-[50%]
                translate-y-[-50%]
                left-[50%]
                translate-x-[-50%]
                rounded-[62px]
                xl:w-[1000px]
                lg:w-[720px]
                xl:h-[885px]
                lg:h-[1030px]
                max-h-[100vh]
                bg-[rgba(0,0,0,.1)]
            `}
        role="button"
        tabIndex="0"
        onClick={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
      >
        <div
          className={`
                    flex
                    bg-white
                    relative
                    top-[1px]
                    ${modalClose}
                `}
        >
          <button
            className="
                            relative
                            top-[20px]
                            ml-auto
                            mr-[50px]
                            h-[56px]
                            font-rubik
                            text-40
                            text-neutral-500
                        "
            type="button"
            tabIndex="0"
            onClick={() => onClose()}
            style={{
              color: '#838383',
            }}
          >
            x
          </button>
        </div>
        <div
          className={`
          ${modalChildren}

        `}
        >
          {children}
        </div>
      </div>
    </section>
  );
}

export default Modal;
